import {EagleApiInterface, EagleModuleApiInterface} from 'kernel/api/api'

export default (api: EagleApiInterface) => {
  api.collection.memberApi = <EagleModuleApiInterface>{
    profile: () => api.request({
      url: `/api/member/profile`,
      type: 'get',
      disableTokenInvalidWarning: true,
    }),
    read: (id: string) => api.request({
      url: `/api/member/${id}`,
      type: 'get',
    }),
    index: (params = {}) => api.request({
      url: `/api/member/index`,
      type: 'get',
      params,
    }),
    create: (params = {}) => api.request({
      url: `/api/member`,
      type: 'post',
      params,
    }),
    update: (id: string, params = {}) => api.request({
      url: `/api/member/${id}/update`,
      type: 'put',
      params,
    }),
    patch: (id: string, column: string, value: any) => api.request({
      url: `/api/member/${id}`,
      type: 'patch',
      params: {
        column,
        value,
      },
    }),
    delete: (id: string) => api.request({
      url: `/api/member/${id}`,
      type: 'delete',
    }),
    batch: (targets: string[], column: string, value: any) => api.request({
      url: `/api/member/batch`,
      type: 'patch',
      params: {
        ids: targets,
        column,
        value,
      },
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/member/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }

  api.collection.providerMemberApi = <EagleModuleApiInterface> {
    index: (providerId, params = {}) => api.request({
      url: `/api/provider/${providerId}/provider-member/index`,
      type: 'get',
      params,
    }),
    read: (providerId, id: string) => api.request({
      url: `/api/provider/${providerId}/provider-member/${id}`,
      type: 'get',
    }),
    delete: (providerId: string, memberId: string) => api.request({
      url: `/api/provider/${providerId}/provider-member/members/${memberId}`,
      type: 'delete',
    }),
    update: (providerId, id: string, params = {}) => api.request({
      url: `/api/provider/${providerId}/provider-member/${id}/update`,
      type: 'put',
      params,
    }),
    updateRole: (providerId, id: string, params = {}) => api.request({
      url: `/api/provider/${providerId}/member/${id}/role`,
      type: 'put',
      params,
    }),
  }

  api.collection.storeMemberApi = <EagleModuleApiInterface> {
    // 把商店所有的管理員撈出來(不分provider)
    managersIndex: (store_id: string, params) => api.request({
      url: `/api/store/${store_id}/mangers/index`,
      type: 'get',
      params,
    }),
    // 撈該provider底下的特定商店的管理員
    storeToProviderToMangersIndex: (store_id: string, provider_id: string ,params) => api.request({
      url: `/api/store/${store_id}/provider/${provider_id}/mangers/index`,
      type: 'get',
      params,
    }),
    read: (id: string) => api.request({
      url: `/api/provider-member/${id}`,
      type: 'get',
    }),
    delete: (storeId: string, memberId: string) => api.request({
      url: `/api/store/${storeId}/members/${memberId}/relieve`,
      type: 'delete',
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/provider-member/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
    update: (id: string, params = {}) => api.request({
      url: `/api/provider-member/${id}/update`,
      type: 'put',
      params,
    }),
    updateRole: (storeId, id: string, params = {}) => api.request({
      url: `/api/store/${storeId}/member/${id}/role`,
      type: 'put',
      params,
    }),
  }
}
